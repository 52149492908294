import Layout from "../../components/Layout";
import React from "react";
import EducatorCategoryList from "../../components/EducatorCategoryList";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Inspiration Category | Educator Resources";
  const description = "TBD";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Inspiration</h1>
            <p>TBD</p>
            <EducatorCategoryList category="inspiration" />
          </div>
        </div>
      </section>
    </Layout>
  );
};
